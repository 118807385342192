import { getAllCheckedProductIds, getAllCheckedProductIdsAndCounts } from './_list';

const orderProducts = (type) => {
    if (type == 'selection' && getAllCheckedProductIds() <= 0) {
        alert($('#i18_bookmarks_no_selection').val());
        return;
    }
    
    $.post(
        $('#order_form_url').val(),
        {
            _token: $('#_token').val(),
            type: type,
            ids: getAllCheckedProductIdsAndCounts().join(','),
            order_source: $('.result-item__products').data('type'),
        },
        function (data) {
            $('#ajaxModal').html(data);
            $('#ajaxModal').modal('show');

            if ($('.bookmarks.dropzone').length > 0) {
                $('.bookmarks.dropzone').dropzone({
                    url: $('#order_ajax_upload_url').val(),
                    maxFilesize: $('#_max_filesize').val(),
                    paramName: 'files',
                    parallelUploads: 5,
                    maxFiles: 5,
                    createImageThumbnails: false,
                    //acceptedFiles: 'image/*,application/pdf',
                    addRemoveLinks: true,
                    dictDefaultMessage: $('#i18_dropzone_dictDefaultMessage').val(),
                    dictFallbackText: $('#i18_dropzone_dictFallbackText').val(),
                    dictFallbackMessage: $('#i18_dropzone_dictFallbackMessage').val(),
                    dictFileTooBig: $('#i18_dropzone_dictFileTooBig').val(),
                    dictInvalidFileType: $('#i18_dropzone_dictInvalidFileType').val(),
                    dictResponseError: $('#i18_dropzone_dictResponseError').val(),
                    dictCancelUpload: $('#i18_dropzone_dictCancelUpload').val(),
                    dictUploadCanceled: $('#i18_dropzone_dictUploadCanceled').val(),
                    dictCancelUploadConfirmation: $('#i18_dropzone_dictCancelUploadConfirmation').val(),
                    dictRemoveFile: $('#i18_dropzone_dictRemoveFile').val(),
                    dictRemoveFileConfirmation: $('#i18_dropzone_dictRemoveFileConfirmation').val(),
                    dictMaxFilesExceeded: $('#i18_dropzone_dictMaxFilesExceeded').val(),
                    init: function () {
                        this.on('sending', function (e, xhr, formData) {
                            formData.append('_token', $('#_token').val());
                            formData.append('_upload_identifier', $('#upload_identifier').val());
                        });

                        this.on('success', function(e, xhr) {
                            console.log('success');
                            console.log(xhr);
                        });
                    },
                });
            }
        }
    );
};
$(document).on('click', '.order_add_multiple', function (e) {
    orderProducts('selection');
});
$(document).on('click', '.products_order_submit_btn', function (e) {
    var self = $(this);
    e.preventDefault();

    if ($('#products_order_form').valid()) {
        let data = $('#products_order_form').serializeArray();
        data.push({
            name: '_token',
            value: $('#_token').val(),
        });

        data.push({
            name: 'order_source',
            value: $('.result-item__products').data('type'),
        });

        var removeProducts = $('#remove-products').is(':checked') ? 1 : 0;
        data.push({
            name: 'remove_products',
            value: removeProducts,
        });

        $.post($('#ajax_order_submit_url').val(), data, function () {
            $('#ajaxModal').modal('hide');

            setTimeout(function () {
                $('.alert').hide().html($('#i18n_offers_ajax_inquery_success').val()).show();

                $('#no_bookmarks').show();
                $('#contact_area').hide();
                $('#products').hide();

                setTimeout(function () {
                    if (removeProducts == 1) {
                        window.location.href += '#orderSuccess';
                        window.location.reload();
                    }
                }, 100);

                if ($('#is_rating_disabled').val() == '0') {
                    window.showRating();
                }

                $('.js-check-inventory').val('0');
                $('.products input[type=checkbox]').prop('checked', false);
            }, 500);
        });
        return;
    }
});
$(document).ready(function () {
    if (window.location.hash == '#orderSuccess') {
        $('.alert').hide().html($('#i18n_offers_ajax_inquery_success').val()).show();
    }
});
export default orderProducts;
