const blanketPositions = {
    initUpload: function() {
        if ($('.blanketpositions.dropzone').length > 0) {
            $('.blanketpositions.dropzone').dropzone({
                url: $('#blanket_position_ajax_upload_url').val(),
                maxFilesize: $('#_max_filesize').val(),
                paramName: 'files',
                parallelUploads: 5,
                maxFiles: 5,
                createImageThumbnails: false,
                //acceptedFiles: 'image/*,application/pdf',
                addRemoveLinks: true,
                dictDefaultMessage: $('#i18_dropzone_dictDefaultMessage').val(),
                dictFallbackText: $('#i18_dropzone_dictFallbackText').val(),
                dictFallbackMessage: $('#i18_dropzone_dictFallbackMessage').val(),
                dictFileTooBig: $('#i18_dropzone_dictFileTooBig').val(),
                dictInvalidFileType: $('#i18_dropzone_dictInvalidFileType').val(),
                dictResponseError: $('#i18_dropzone_dictResponseError').val(),
                dictCancelUpload: $('#i18_dropzone_dictCancelUpload').val(),
                dictUploadCanceled: $('#i18_dropzone_dictUploadCanceled').val(),
                dictCancelUploadConfirmation: $('#i18_dropzone_dictCancelUploadConfirmation').val(),
                dictRemoveFile: $('#i18_dropzone_dictRemoveFile').val(),
                dictRemoveFileConfirmation: $('#i18_dropzone_dictRemoveFileConfirmation').val(),
                dictMaxFilesExceeded: $('#i18_dropzone_dictMaxFilesExceeded').val(),
                init: function() {
                    this.on('sending', function(e, xhr, formData) {
                        formData.append('_token', $('#_token').val());
                        formData.append('_upload_identifier', $('#upload_identifier').val());
                    });
                },
            });
        }
    }
};

$(document).on('click', '.blanket_position_inquery', function(e) {
    e.preventDefault();

    let ids = [$(this).data('id')];
    let qtys = [$("input[name='call_of_quantity'][data-id='"+$(this).data('id')+"']").val()];

    $.ajax($('#blanket_position_ajax_inquery_url').val(), {
        method: 'POST',
        data: {
            _token: $('#_token').val(),
            ids: ids.join(','),
            qtys: qtys.join(','),
        },
        success: function(data) {
            $('#ajaxModal').html(data);
            $('#ajaxModal').modal('show');
            blanketPositions.initUpload();
        },
    });
});

$(document).on('click', '.blanket_position_inquery_multiple', function(e) {
    e.preventDefault();
    var ids = [];
    var qtys = [];

    $('.maincontent')
        .find('td input[type=checkbox]:checked')
        .each(function() {
            var id = $(this)
                .closest('tr')
                .data('id');

            if (id) {
                ids.push(id);
                var qty = $("input[name='call_of_quantity'][data-id='"+id+"']").val();
                if(qty <= 0) {
                    alert($('#i18_no_quantity').val());
                    return;
                }
                qtys.push(qty);
            }
        });

    if (ids.length == 0) {
        alert($('#i18_no_selection').val());
        return;
    }

    $.ajax($('#blanket_position_ajax_inquery_url').val(), {
        method: 'POST',
        data: {
            _token: $('#_token').val(),
            ids: ids.join(','),
            qtys: qtys.join(','),
        },
        success: function(data) {
            $('#ajaxModal').html(data);
            $('#ajaxModal').modal('show');
            blanketPositions.initUpload();
        },
    });
});

$(document).on('click', '.blanket_position_inquery_submit', function(e) {
    var self = $(this);
    e.preventDefault();

    if ($('#blanket_position_inquery_form').valid()) {
        let data = $('#blanket_position_inquery_form').serializeArray();
        data.push({
            name: '_token',
            value: $('#_token').val(),
        });

        $.post($('#blanket_position_ajax_inquery_submit_url').val(), data, function() {
            $('#ajaxModal').modal('hide');

            setTimeout(function() {
                $('.alert')
                    .hide()
                    .html($('#i18n_offers_ajax_inquery_success').val())
                    .show();
            }, 500);
        });
        return;
    }
});

$(document).ready(function() {
    $("input[name='call_of_quantity']").each(function(k, v) {
        $(v).on('keyup change', function() {
            if($(v).val() > 0) {
                $("input[name='blanket_position_selection_"+$(v).attr("data-id")+"']").prop("checked", true);
            } else {
                $("input[name='blanket_position_selection_"+$(v).attr("data-id")+"']").prop("checked", false);
            }
        });
        $("input[name='blanket_position_selection_"+$(v).attr("data-id")+"']").on("change", function(event) {
            let quantity = $("input[name='call_of_quantity'][data-id='"+$(this).attr("data-id")+"']");
            if($(this).is(":checked") && quantity.val() == "0") {
                quantity.val("1");
            }
            if(!$(this).is(":checked")) {
                quantity.val("0");
            }
        });
    });
});