import print from './_print';
import orderProducts from './_order';
import { getAllCheckedProductIds, getAllProductIds, getAllCheckedProductIdsAndCounts } from './_list';

const init = () => {
    if (!$('body').hasClass('page-bookmarks')) return;

    let select = $('select[name=bookmark_control]');
    select.each(function(i, item) {
        $(item).select2({
            selectOnClose: false,
        });

        $(item).on('select2:select', function(e) {
            itemSelected($(item));
        });
    });

    showMoreBookmarkLists();
};

const showMoreBookmarkLists = () => {
    let button = $('.bookmark-selection__control');
    button.on('click', function(e) {
        e.stopPropagation();
        $(this)
            .find('ul')
            .toggleClass('show');
    });

    button.find('ul li').on('click', function(e) {
        e.preventDefault();
        let id = $(this).data('id');
        window.location = $('#url_bookmark-lists_switch_get').val() + '/' + id;
    });

    $(window).on('click', function() {
        let menu = $('.bookmark-selection__options');
        if (!menu || !menu.length) return;

        menu.removeClass('show');
    });
};

const printProducts = type => {
    if (type == 'selection' && getAllCheckedProductIds() <= 0) {
        alert($('#i18_bookmarks_no_selection').val());
        return;
    }

    var ids = [];

    if (type == 'selection') {
        ids = getAllCheckedProductIds();
    } else {
        ids = getAllProductIds();
    }

    return print({
        url: $('#url_products-print_get').val() + '/' + ids.join(','),
    });
};

const printList = () => {
    return print({
        url: $('#url_list-print_get').val(),
    });
};

const itemSelected = element => {
    if (!element) return;
    let action = element.val();
    let type = element.data('type'); // can be entire list or selected products

    console.log('Selected Action', action, ' with ', type);

    /**
     * Hier kommt dann die Logik hin die bei dem jeweiligen
     * Klick ausgeführt werden soll. Value ist dann gleich
     * das Event/Function welche getriggert werden soll
     */
    eventHandler(action, type);
    element.select2('val', 0);
};

const eventHandler = (event, type) => {
    if (!event) return;

    switch (event) {
        case 'enquiriesProducts':
            return enquiriesProducts(type);
        case 'recommendProducts':
            return recommendProducts(type);
            break;
        case 'orderProducts':
            return orderProducts(type);
            break;
        case 'printProducts':
            return printProducts(type);
            break;
        case 'printList':
            return printList();
            break;
        case 'saveProducts':
            return saveProducts(type);
            break;
        case 'deleteProducts':
            console.log('Execute', event);
            removeProduct();
            break;
        default:
            return false;
    }
};

const enquiriesProducts = (type, articleNumber, count) => {
    if (type == 'selection' && getAllCheckedProductIds() <= 0) {
        alert($('#i18_bookmarks_no_selection').val());
        return;
    }

    let postData = {
        _token: $('#_token').val(),
        type: type,
    };

    if (type != 'single') {
        postData.ids = getAllCheckedProductIds().join(',');
    } else {
        postData.ids = articleNumber;
        postData.count = count;
    }

    $.post($('#bookmarks_enquiries_form_url').val(), postData, function(data) {
        $('#ajaxModal').html(data);
        $('#ajaxModal').modal('show');

        if ($('.bookmarks.dropzone').length > 0) {
            $('.bookmarks.dropzone').dropzone({
                url: $('#bookmarks_ajax_upload_url').val(),
                maxFilesize: $('#_max_filesize').val(),
                paramName: 'files',
                parallelUploads: 5,
                maxFiles: 5,
                createImageThumbnails: false,
                //acceptedFiles: 'image/*,application/pdf',
                addRemoveLinks: true,
                dictDefaultMessage: $('#i18_dropzone_dictDefaultMessage').val(),
                dictFallbackText: $('#i18_dropzone_dictFallbackText').val(),
                dictFallbackMessage: $('#i18_dropzone_dictFallbackMessage').val(),
                dictFileTooBig: $('#i18_dropzone_dictFileTooBig').val(),
                dictInvalidFileType: $('#i18_dropzone_dictInvalidFileType').val(),
                dictResponseError: $('#i18_dropzone_dictResponseError').val(),
                dictCancelUpload: $('#i18_dropzone_dictCancelUpload').val(),
                dictUploadCanceled: $('#i18_dropzone_dictUploadCanceled').val(),
                dictCancelUploadConfirmation: $('#i18_dropzone_dictCancelUploadConfirmation').val(),
                dictRemoveFile: $('#i18_dropzone_dictRemoveFile').val(),
                dictRemoveFileConfirmation: $('#i18_dropzone_dictRemoveFileConfirmation').val(),
                dictMaxFilesExceeded: $('#i18_dropzone_dictMaxFilesExceeded').val(),
                init: function() {
                    this.on('sending', function(e, xhr, formData) {
                        formData.append('_token', $('#_token').val());
                        formData.append('_upload_identifier', $('#upload_identifier').val());
                    });

                    this.on('success', function(e, xhr) {
                        console.log('success');
                        console.log(xhr);
                    });
                },
            });
        }
    });
};

window.enquiriesProducts = enquiriesProducts; // Für den Link "Preis anfragen" in der Produktliste

const recommendProducts = type => {
    if (type == 'selection' && getAllCheckedProductIds() <= 0) {
        alert($('#i18_bookmarks_no_selection').val());
        return;
    }

    var ids = [];

    if (type == 'selection') {
        ids = getAllCheckedProductIds();
    } else {
        ids = getAllProductIds();
    }

    $.get($('#url_bookmarks-recommendation_get').val() + '/' + ids.join(','), [], function(data) {
        $('#ajaxModal').html(data);
        $('#ajaxModal').modal('show');
    });
};

const saveProducts = type => {
    if (type == 'selection' && getAllCheckedProductIdsAndCounts() <= 0) {
        alert($('#i18_bookmarks_no_selection').val());
        return;
    }

    $.get(
        $('#url_bookmark-lists_save-as-new-list_post').val(),
        {
            product_ids: getAllCheckedProductIdsAndCounts().join(','),
        },
        function(data) {
            $('#ajaxModal').html(data);
            $('#ajaxModal').modal('show');
        }
    );
};

$(document).on('click', '.bookmark_save-as-new-list_submit_btn', function(e) {
    var self = $(this);
    e.preventDefault();

    if ($('#bookmark_save-as-new-list').valid()) {
        let data = $('#bookmark_save-as-new-list').serializeArray();

        data.push({
            name: '_token',
            value: $('#_token').val(),
        });

        $.post($('#url_bookmark-lists_save-as-new-list_post').val(), data, function(data) {
            $('#ajaxModal').modal('hide');
            window.location = $('#url_bookmarks-list_get').val();
        });
        return;
    }
});

$(document).on('click', '.bookmarks_enquiries_submit_btn', function(e) {
    var self = $(this);
    e.preventDefault();

    if ($('#bookmark_form').valid()) {
        let data = $('#bookmark_form').serializeArray();
        data.push({
            name: '_token',
            value: $('#_token').val(),
        });

        if (
            $('#bookmark_form')
                .find('input[name=product_ids]')
                .val().length == 0
        ) {
            data.push({
                name: 'product_ids',
                value: getAllCheckedProductIds().join(','),
            });
        }

        $.post($('#bookmarks_ajax_enquiries_submit_url').val(), data, function() {
            $('#ajaxModal').modal('hide');

            setTimeout(function() {
                $('.alert')
                    .hide()
                    .html($('#i18n_contacts_ajax_submit_success').val())
                    .show();

                $('#no_bookmarks').show();
                $('#contact_area').hide();
                $('#products').hide();

                if ($('#is_rating_disabled').val() == '0') {
                    window.showRating();
                }
            }, 500);
        });
        return;
    }
});

$(document).on('click', '.bookmarks_recommendation_submit_btn', function(e) {
    var self = $(this);
    e.preventDefault();

    if ($('#bookmark_recommendation_form').valid()) {
        let data = $('#bookmark_recommendation_form').serializeArray();
        data.push({
            name: '_token',
            value: $('#_token').val(),
        });

        $.post($('#url_bookmarks-recommendation_post').val(), data, function() {
            $('#ajaxModal').modal('hide');

            setTimeout(function() {
                $('.alert')
                    .hide()
                    .html($('#i18n_bookmarks-recommendation_success').val())
                    .show();

                $('#no_bookmarks').show();
                $('#contact_area').hide();
                $('#products').hide();

                if ($('#is_rating_disabled').val() == '0') {
                    window.showRating();
                }
            }, 500);
        });
        return;
    }
});

$(document).on('click', '.bookmarks-print', function(e) {
    e.preventDefault();
    let url = $(this).data('url');

    return print({
        url: url,
        title: $(document)
            .find('h1')
            .text(),
    });
});

/**
 * Events
 */
const removeProduct = () => {
    let productIds = getAllCheckedProductIds();
    if (!productIds || !productIds.length) return;

    $.ajax($('#bookmarks_ajax_remove_multiple_url').val(), {
        method: 'POST',
        data: {
            _token: $('#_token').val(),
            products_id: productIds.join(','),
        },
        success: function(resp) {
            console.log('%c Bookmarks successfully deleted!', 'color: #bada55');
            return location.reload();
        },
    });
};

init();
